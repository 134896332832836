import * as React from "react";
import { Link, HeadFC } from "gatsby";
import Navbar from "../components/navbar/navbar";
import Footer from "../components/footer/footer";

const NotFoundPage = () => {
    return (
        <div className={"overflow-x-hidden"}>
            <Navbar />
            <main className={"relative z-0 dark:text-stone-50/75"}>
                <div className={"px-8 md:container md:mx-auto md:flex md:justify-center"}>
                    <div className={"pt-16 pb-24 md:flex-none md:basis-[50ch]"}>
                        <h1 className={"text-4xl font-bold opacity-30 md:text-6xl"}>404</h1>
                        <h2 className={"text-xl font-bold opacity-30 md:text-3xl"}>Page not found</h2>

                        <p className={"pb-8 pt-16"}>Sorry, the page you were looking for doesn't exist.</p>
                        <p className={"pb-8"}>
                            <Link to={"/"} className={"linkarrow inline-flex items-center text-rose-600"}>
                                <div className={"flex-none"}>Go to the Maildrop homepage</div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="h-5 w-5">
                                    <path
                                        fillRule="evenodd"
                                        d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </Link>
                        </p>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Not found</title>;
